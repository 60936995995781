import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import Seo from './../components/Layout/Seo'
import History from './../components/History/Page'
import InstagramFeed from './../components/Instagram/InstagramFeed'

const StyledHistory = styled(History)`
    position: relative;

    padding: 5rem 0 0 0;

    .generations-slider {
        background-color: ${props => props.theme.colors.darkMedium};
    }

    ${props => props.theme.above.desktop`
        padding: 0;

        .generations-introduction {
            background-color: ${props => props.theme.colors.darkMedium};
        }
        
        .generations-slider {
            background-color: transparent;
        }
    `}
`

const HistoryPage = ({
    data: {
        instagram,
        history: {
            lang,
            langSlug,
            data
        }
    }
}) => {
    return (
        <>
            <Seo
                title={data.seo_title}
                description={data.seo_description.text}
                keywords={data.seo_keywords}
                image={data.seo_image}
            />
            <StyledHistory 
                lang={langSlug}
                data={data} 
            />
            <InstagramFeed
                copy={instagram}
            />
        </>
    )
}

export const QUERY_HISTORYPAGE = graphql`
    query HistoryPage($id: String!, $lang: String!) {
        history: prismicHistoryPage(id: { eq: $id }) {
            id
            lang
            ...HistoryPageQuery
        }
        instagram: prismicInstagramComponent(lang: { eq: $lang }) {
            id
            lang
            ...InstagramQuery
        }
    }
`

export default HistoryPage