import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { motion } from 'framer-motion'

const Grid = styled(motion.ul)`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    padding: 0 1.5rem;

    ${props => props.theme.above.desktop`
        display: none;
    `}
`

const Item = styled(motion.li)`
    width: calc(100% / 3);

    padding: 0.5rem;
`
const Link = styled.a``

const StyledImg = styled(Img)`
    picture {
        img {
            opacity: 1 !important;
        }
    }
`

const InstagramGrid = ({
    data, 
    animation,
    className
}) => {
    return (
        <Grid
            className={className}
            animate={animation}
            variants={{
                before: {
                    opacity: 0,
                    y: 25
                },
                after: {
                    opacity: 1,
                    y: 0,
                }
            }}
            transition={{
                duration: 1,
                damping: 100,
                stiffness: 100,
                ease: 'circOut',
                transition: {
                    staggerChildren: 0.1
                }
            }}
        >
            {data.slice(0, 6).map(({ instagram_image }, index) => {
                return (
                    <Item
                        key={index}
                        variants={{
                            before: {
                                opacity: 0
                            },
                            after: {
                                opacity: 1
                            }
                        }}
                    >
                        {/* <Link href={item.node.link} target='_blank'> */}
                            <StyledImg fluid={instagram_image.fluid} alt={instagram_image.alt} />
                        {/* </Link> */}
                    </Item>
                )
            })}
        </Grid>
    )
}

export default InstagramGrid
