import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { motion } from "framer-motion"

import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"

import InstagramSVG from "./../../graphics/instagram.svg"

const Carousel = styled(motion.div)`
    cursor: grab;

    &:active {
        cursor: grabbing;
    }

    ${props => props.theme.below.desktop`
        display: none;
    `}
`

const Item = styled(motion.div)`
    padding: 0 1.5rem;

    text-align: left;
`

const Inner = styled.div`
    position: relative;

    width: 100%;
    height: 100%;

    pointer-events: none;
`

const StyledImg = styled(Img)`
    position: relative;

    width: 100%;
    height: 100%;

    object-fit: cover;

    picture {
        img {
            opacity: 1 !important;
        }
    }
`

const Icon = styled.span`
    display: block;
    position: absolute;

    top: 2rem;
    left: 2rem;

    width: 1.5rem;
    height: 1.5rem;

    ${props => props.theme.above.desktop`
        top: 3rem;
        left: 3rem;
    `}
`

const StyledInstagram = styled(InstagramSVG)`
    width: 100%;
    height: 100%;
`

const Username = styled.span`
    position: absolute;

    transform: rotate(-90deg);
    transform-origin: 0px 0px;

    left: 2rem;
    padding-left: 2rem;

    font-family: ${props => props.theme.fontFamily.circularBook};
    font-size: 1.2rem;
    letter-spacing: 0.1rem;

    color: ${props => props.theme.colors.white};

    ${props => props.theme.above.desktop`
        left: 3rem;
        padding-left: 3rem;
    `}
`

const InstagramCarousel = ({ data, animation, className }) => {
    const handleOnDragStart = e => {
        e.preventDefault()
    }

    const params = {
        centeredSlides: true,
        initialSlide: 1,
        loop: true,
        preloadImages: false,
        grabCursor: true,
        spaceBetween: 15,
        breakpoints: {
            0: {
                slidesPerView: 1,
            },
            800: {
                slidesPerView: 4,
            },
            1600: {
                slidesPerView: 5,
            },
            2500: {
                slidesPerView: 7,
            },
        },
    }

    return (
        <Carousel
            className={className}
            animate={animation}
            variants={{
                before: {
                    opacity: 0,
                    y: 25,
                },
                after: {
                    opacity: 1,
                    y: 0,
                },
            }}
            transition={{
                duration: 1,
                damping: 100,
                stiffness: 100,
                ease: "circOut",
                transition: {
                    staggerChildren: 0.1,
                },
            }}
        >
            <Swiper {...params}>
                {data.map(({ instagram_image }, index) => {
                    return (
                        <Item
                            key={index}
                            onDragStart={handleOnDragStart}
                            variants={{
                                before: {
                                    opacity: 0,
                                },
                                after: {
                                    opacity: 1,
                                },
                            }}
                        >
                            <Inner>
                                <StyledImg
                                    fluid={instagram_image.fluid}
                                    alt={instagram_image.alt}
                                    fadeIn={false}
                                    loading="eager"
                                />
                                <Icon>
                                    <StyledInstagram />
                                </Icon>
                                <Username>@living.creations</Username>
                            </Inner>
                        </Item>
                    )
                })}
            </Swiper>
        </Carousel>
    )
}

export default InstagramCarousel
