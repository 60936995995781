import React from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'
import { graphql, StaticQuery } from 'gatsby'

import Title from './../Title'
import InstagramCarousel from './InstagramCarousel'
import InstagramGrid from './InstagramGrid'

const Wrapper = styled.section`
    padding: 5rem 0 3rem 0;
    
    background-color: ${props => props.theme.colors.darkMedium};

    text-align: center;

    ${props => props.theme.above.desktop`
        padding: 10rem 0;

        text-align: left;
    `}
`

const StyledTitle = styled(Title)`
    display: none;

    ${props => props.theme.above.desktop`
        display: block;

        margin-bottom: 6rem;
    `}
`

const LinkWrapper = styled.div`
    display: inline-block;

    overflow: hidden;
        
    margin-top: 2.5rem;

    ${props => props.theme.above.desktop`
        display: none;
    `}
`

const Link = styled(motion.a)``

const InstagramFeed = ({ copy }) => {

    const [ref, inView, entry] = useInView({
        threshold: 0.2,
        triggerOnce: true
    })

    const animation = inView ? 'after' : 'before'

    return (
        <StaticQuery query={graphql`
            query {
                allPrismicInstagramComponent {
                    nodes {
                        data {
                            images {
                                instagram_image {
                                    alt
                                    fluid(maxWidth: 324) {
                                        ...GatsbyPrismicImageFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }`}
            render={(data) => {
                return (
                    <Wrapper name='instagram' id='instagram' ref={ref}>
                        <StyledTitle
                            href={copy.data.link.url}
                            subtitle={copy.data.subtitle.text}
                            title={copy.data.title.text}
                            alignment='center'
                        />
                        <InstagramCarousel 
                            animation={animation} 
                            data={data.allPrismicInstagramComponent.nodes[0].data.images} 
                        />
                        <InstagramGrid
                            animation={animation} 
                            data={data.allPrismicInstagramComponent.nodes[0].data.images} 
                        />
                        <LinkWrapper>
                            <Link 
                                href={copy.data.link.url} 
                                target='_blank' 
                                className='mobile'
                                animate={animation}
                                variants={{
                                    before: {
                                        opacity: 0,
                                        y: -100,
                                        skewX: 3
                                    },
                                    after: {
                                        opacity: 1,
                                        y: 0,
                                        skewX: 0
                                    }
                                }}
                                transition={{
                                    duration: 0.55,
                                    damping: 100,
                                    stiffness: 100,
                                    ease: 'circOut',
                                }}
                            >{copy.data.subtitle.text}</Link>
                        </LinkWrapper>
                    </Wrapper>
                )
            }}
        />
    )
}

export default InstagramFeed